import(/* webpackMode: "eager" */ "/src/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.8_supports-color@8.1.1__@opentelemetry+api@1.7.0_react-do_0836945c44c55af46d0b2175e0dacf67/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder1.webp");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder2.webp");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder3.webp");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder4.webp");
;
import(/* webpackMode: "eager" */ "/src/public/images/placeholder5.webp");
;
import(/* webpackMode: "eager" */ "/src/src/app/_lib/PageWrappers/SlugPages/ArticlePage/ArticlePage.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/app/_lib/PageWrappers/SlugPages/Savedesk/SavedeskPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tracking"] */ "/src/src/app/_lib/tracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/components/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/global/utils/HtmlFromCMS.tsx");
;
import(/* webpackMode: "eager" */ "/src/src/newComponents/CmsBlocks/BlockList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/src/newComponents/Divider/Divider.tsx");
