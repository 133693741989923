import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { ErrorMessageReferenceKey, ErrorMessageWrappedInContent } from '@/types/error';
import { MaintenanceMode } from '@/constants';

import { BlockListItem, ContentPageSuffix, Link, NotificationType } from './generalCmsData';
import { ErrorPage } from './startPageCmsData';

type LegacyFooterSection = {
  header: string;
  links: Link[];
};

type PageNotification = {
  notificationBody?: string;
  notificationHeadline: string;
  notificationShowOnPages: {
    $values: string[];
  };
  notificationType: string;
};

export type SettingsCmsData = {
  errorMessages: ErrorMessageWrappedInContent<ErrorMessageReferenceKey>[];
  footerSections: LegacyFooterSection[];
  maintainedPages: null;
  maintainedUrlsRegularExpression?: string;
  maintenanceButtonText: string;
  maintenanceHeading: string;
  maintenancePageText: string;
  notificationBlockList?: BlockListItem<PageNotification>[];
  showMaintenancePage: false;
} & ContentPageSuffix;

export type FooterLinks = {
  name: string;
  target: null;
  type: number;
  udi: null;
  url: string;
};

export type NotificationContent = {
  notificationBody: string;
  notificationHeadline: string;
  notificationShowOnPages: {
    $values: Array<string>;
  };
  notificationType: NotificationType;
};

export type NotificationBlockList = [
  {
    content: NotificationContent;
  }
];

export enum FooterSectionItemType {
  RichTextElement = 'RichTextElement',
  Button = 'Button',
  LinkElement = 'LinkElement',
}

export type FooterSectionTextElement = {
  $type: FooterSectionItemType.RichTextElement;
  label: string;
  text: string;
};

export type FooterSectionButton = {
  $type: FooterSectionItemType.Button;
  buttonLink: {
    name: string;
    url: string;
  };
  buttonText: string;
  text: string;
};

export type FooterSectionLink = {
  $type: FooterSectionItemType.LinkElement;
  link: {
    name: string;
    url: string;
    target: string;
  };
};

export type FooterLinkItem = BlockListItem<FooterSectionTextElement | FooterSectionButton | FooterSectionLink>;

export type FooterSectionContent = {
  headline: string;
  icon: IconKey;
  footerItems: FooterLinkItem[];
};

type SocialMediaLink = {
  altText: string;
  icon: IconKey;
  link: Link;
};

export type FooterMinimal = {
  copyrightText: string;
  privacyPolicyLink: Link;
  socialMediaHeader: string;
  socialMediaLinks: SocialMediaLink[];
  termsAndConditionsLink: Link;
};

export type FooterSection = BlockListItem<FooterSectionContent>;

export type HeaderLink = {
  text: string;
  link: Link;
  sublinks?: BlockListItem<HeaderLink>[];
};

export type HeaderIconLink = {
  icon: IconKey;
  text: string;
  link: Link;
};

export type HeaderComplementaryNavigation = {
  type: 'link' | 'cart-b2c' | 'cart-b2b';
} & HeaderIconLink;

export type CmsSettings = {
  appVersion: string;
  errorMessages: ErrorMessageWrappedInContent<string>[];
  footerMinimalB2B: FooterMinimal[];
  footerMinimalB2C: FooterMinimal[];
  footerSectionsB2B: FooterSection[];
  footerSectionsB2C: FooterSection[];

  headerB2BcomplementaryNavigation: BlockListItem<HeaderComplementaryNavigation>[];
  headerB2Blink: Link;
  headerB2BlinkText: string;
  headerB2BloginIcon: BlockListItem<HeaderIconLink>[];
  headerB2BmainNavigation: BlockListItem<HeaderLink>[];
  headerB2BmyPagesIcon: BlockListItem<HeaderIconLink>[];
  headerB2CcomplementaryNavigation: BlockListItem<HeaderComplementaryNavigation>[];
  headerB2Clink: Link;
  headerB2ClinkText: string;
  headerB2CloginIcon: BlockListItem<HeaderIconLink>[];
  headerB2CmainNavigation: BlockListItem<HeaderLink>[];
  headerB2CmyPagesIcon: BlockListItem<HeaderIconLink>[];
  headerGoToMyPages: string;
  headerLoggedInAs: string;
  headerLoginAs: string;
  headerLogOut: string;
  headerMenuLinkText: string;
  headerMyPagesNavigation: BlockListItem<HeaderLink>[];
  headerSelectAccount: string;
  headerSkipToMainContent: string;

  id: number;
  maintenanceModeContent: ErrorPage;
  maintenanceModeStatus: MaintenanceMode;
  name: string;
  notificationBlockList: NotificationBlockList;
  url: string;
};
