import {
  ActionPageInformationBlock,
  BlockListItem,
  ContentPageSuffix,
  FaqBlock,
  Offer,
  Usp,
} from '@/types/cms/generalCmsData';
import { ErrorMessageWrappedInContent } from '@/types/error';
import { Product } from '../product';
import { ExtraSurfAddOn } from './extraSurfCmsData';

export enum ExtraUserSalesCmsErrorReference {
  EXTRA_USER_NOT_B2C = 'EXTRA_USER_NOT_B2C',
  EXTRA_USER_NO_MAIN_SUBSCRIPTION = 'EXTRA_USER_NO_MAIN_SUBSCRIPTION',
  EXTRA_USER_TOO_MANY_SUBSCRIPTIONS = 'EXTRA_USER_TOO_MANY_SUBSCRIPTIONS',
}

export type ExtraUsersCmsData = {
  backLinkText: string;
  buyButtonText: string;
  changeLinkText: string;
  consumedCurrentMonth: string;
  convertLinkText: string;
  errors: ErrorMessageWrappedInContent<ExtraUserSalesCmsErrorReference>[];
  extraSurf: string;
  explanationOfSharedSurf: string;
  extraSurfAddOns: ExtraSurfAddOn[];
  extraSurfExpiration: string;
  extraSurfExplanation: string;
  faq: BlockListItem<FaqBlock>[];
  header: string;
  limitHasBeenReached: string;
  mainSubscriptionHeader: string;
  marketingInformation: BlockListItem<ActionPageInformationBlock>[];
  metaData: {
    productData: Product[];
  };
  noticeWhenLimitHasBeenReached: string;
  offerReference: Offer;
  or: string;
  orderExtraUserButtonText: string;
  price: string;
  productCardHeader: string;
  purchaseNotification: string;
  remainingSharedSurf: string;
  selectMainSubscriptionHeader: string;
  setLimit: string;
  surfLimit: string;
  userListHeader: string;
  uSps: BlockListItem<Usp>[];
} & ContentPageSuffix;
