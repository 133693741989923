import { ErrorMessage } from './cms/generalCmsData';

export enum ErrorBoundaryType {
  Account = 'Account',
  AccountSettings = 'AccountSettings',
  ActivateEsim = 'ActivateEsim',
  AdyenLegacy = 'AdyenLegacy',
  CancelSubscription = 'CancelSubscription',
  ChangeSubscription = 'ChangeSubscription',
  HardwareChooseSubscription = 'HardwareChooseSubscription',
  Checkout = 'Checkout',
  ExtraUser = 'ExtraUser',
  ExtraUserSales = 'ExtraUserSales',
  Footer = 'Footer',
  Generic = 'Generic',
  Hardware = 'Hardware',
  Login = 'Login',
  OrderSim = 'OrderSim',
  PaymentSettings = 'PaymentSettings',
  Subscription = 'Subscription',
  SubscriptionList = 'SubscriptionList',
  InternationalPrices = 'InternationalPrices',
  InternationalCountryPricesB2C = 'InternationalCountryPricesB2C',
  InternationalCountryPricesB2B = 'InternationalCountryPricesB2B',
  PauseSubscription = 'PauseSubscription',
  Slug = 'Slug',
}

export enum ErrorMessageReferenceKey {
  RatorErrorTransactionAuthorizationError = 'RATOR_ERROR_TRANSACTION_AUTHORIZATION_ERROR',
  RatorErrorAdyenPaymentError = 'RATOR_ERROR_ADYEN_PAYMENT_ERROR',
  RatorErrorTransactionException = 'RATOR_ERROR_TRANSACTION_EXCEPTION',
  RatorErrorRateplanAlreadyPresent = 'RATOR_ERROR_RATEPLAN_ALREADY_PRESENT',
  UserContextMismatch = 'ERROR_USER_CONTEXT_MISMATCH',
  Generic = 'GENERIC',
  AddToCartError = 'ADD_TO_CART_ERROR',
}

export type ErrorMessageWrappedInContent<ReferenceType> = {
  content: ErrorMessage<ReferenceType>;
};

export type ErrorBoundaryConfigType = {
  component?: JSX.Element;
  errorType?: ErrorBoundaryType;
  errorMessageReferenceKey?: ErrorMessageReferenceKey;
};

export enum ApiErrorCode {
  // Misc
  ERROR_COULD_NOT_GET_ADDRESS = 'ERROR_COULD_NOT_GET_ADDRESS',
  ERROR_MAX_NUMBER_OF_SUBSCRIPTIONS_REACHED = 'ERROR_MAX_NUMBER_OF_SUBSCRIPTIONS_REACHED',
  ERROR_PACKAGE_IS_NOT_EXTRAUSER = 'ERROR_PACKAGE_IS_NOT_EXTRAUSER',
  ERROR_PACKAGE_NOT_UPDATED = 'ERROR_PACKAGE_NOT_UPDATED',
  ERROR_PARENT_CANNOT_BE_SAME_AS_SUBSCRIPTION = 'ERROR_PARENT_CANNOT_BE_SAME_AS_SUBSCRIPTION',
  ERROR_PARENT_PACKAGE_CANNOT_BECOME_EXTRA_USER = 'ERROR_PARENT_PACKAGE_CANNOT_BECOME_EXTRA_USER',
  ERROR_PARENT_SUBSCRIPTION_NOT_FOUND = 'ERROR_PARENT_SUBSCRIPTION_NOT_FOUND',
  ERROR_SIGNUP_FAILED = 'ERROR_SIGNUP_FAILED',
  ERROR_SUBSCRIPTION_NOT_FOUND = 'ERROR_SUBSCRIPTION_NOT_FOUND',
  ERROR_SURF_LIMIT_NOT_UPDATED = 'ERROR_SURF_LIMIT_NOT_UPDATED',
  ERROR_TERMS_AND_CONDITIONS_NOT_ACCEPTED = 'ERROR_TERMS_AND_CONDITIONS_NOT_ACCEPTED',
  ERROR_TOPUP_NOT_FOUND = 'ERROR_TOPUP_NOT_FOUND',

  // Order
  ERROR_ORDER_CHANGES_NOT_ALLOWED = 'ERROR_ORDER_CHANGES_NOT_ALLOWED',
  ERROR_ORDER_NOT_CREATED = 'ERROR_ORDER_NOT_CREATED',
  ERROR_ORDER_NOT_READY_FOR_PROCESSING = 'ERROR_ORDER_NOT_READY_FOR_PROCESSING',
  ERROR_ORDER_NOT_VALID = 'ERROR_ORDER_NOT_VALID',
  ERROR_ORDER_REQUIRES_HARDWARE_PRODUCT = 'ERROR_ORDER_REQUIRES_HARDWARE_PRODUCT',

  // Confirm order
  ERROR_PAYMENT_METHOD_INVOICE_NOT_ALLOWED = 'ERROR_PAYMENT_METHOD_INVOICE_NOT_ALLOWED',

  // Payment
  ERROR_PAYMENT_CHANGES_NOT_ALLOWED = 'ERROR_PAYMENT_CHANGES_NOT_ALLOWED',
  ERROR_PAYMENT_DETAILS_NOT_VALID = 'ERROR_PAYMENT_DETAILS_NOT_VALID',
  ERROR_PAYMENT_METHOD_NOT_ALLOWED = 'ERROR_PAYMENT_METHOD_NOT_ALLOWED',
  ERROR_PAYMENT_TICKET_AUTHORIZATION_FAILED = 'ERROR_PAYMENT_TICKET_AUTHORIZATION_FAILED',
  ERROR_COULD_NOT_FETCH_HARDWARE_TERMS_AND_CONDITIONS = 'ERROR_COULD_NOT_FETCH_HARDWARE_TERMS_AND_CONDITIONS',
  ERROR_PAYMENT_TIME_EXCEEDED = 'ERROR_PAYMENT_TIME_EXCEEDED',

  // Phonenumber
  ERROR_PHONE_NUMBER_FORMAT_INVALID = 'ERROR_PHONE_NUMBER_FORMAT_INVALID',
  ERROR_PHONE_NUMBER_IN_USE = 'ERROR_PHONE_NUMBER_IN_USE',
  ERROR_PHONE_NUMBER_IN_USE_UNKNOWN = 'ERROR_PHONE_NUMBER_IN_USE_UNKNOWN',
  ERROR_PHONE_NUMBER_RESERVATION_FAILED = 'ERROR_PHONE_NUMBER_RESERVATION_FAILED',
  ERROR_PHONE_NUMBER_RESERVATION_INVALID_NUMBER = 'ERROR_PHONE_NUMBER_RESERVATION_INVALID_NUMBER',
  ERROR_PHONE_NUMBER_RESERVATION_NOTFOUND = 'ERROR_PHONE_NUMBER_RESERVATION_NOTFOUND',
  ERROR_PHONE_NUMBER_PORTING_TO_HALLON_IN_PROGRESS = 'ERROR_PHONE_NUMBER_PORTING_TO_HALLON_IN_PROGRESS',

  // User
  ERROR_USER_BRAND_MISMATCH = 'ERROR_USER_BRAND_MISMATCH',
  ERROR_USER_CONTEXT_MISMATCH = 'ERROR_USER_CONTEXT_MISMATCH',
  ERROR_USER_ID_MISMATCH = 'ERROR_USER_ID_MISMATCH',
  ERROR_USER_IS_BLACKLISTED = 'ERROR_USER_IS_BLACKLISTED',
  ERROR_USER_NAME_NOT_AVAILABLE = 'ERROR_USER_NAME_NOT_AVAILABLE',
  ERROR_USER_NOT_VALID = 'ERROR_USER_NOT_VALID',

  // Voucher
  ERROR_VOUCHER_INVALID = 'ERROR_VOUCHER_INVALID',
  ERROR_VOUCHER_NOT_APPLICABLE = 'ERROR_VOUCHER_NOT_APPLICABLE',

  // Cart
  ERROR_CART_NOT_VALID = 'ERROR_CART_NOT_VALID',
  ERROR_CART_REQUIRES_IDENTIFICATION = 'ERROR_CART_REQUIRES_IDENTIFICATION',
  ERROR_CART_VALIDATION_HARDWARE_OUT_OF_STOCK = 'ERROR_CART_VALIDATION_HARDWARE_OUT_OF_STOCK',
  ERROR_CART_FETCH_NOT_ALLOWED = 'ERROR_CART_FETCH_NOT_ALLOWED',

  // Hardware Invoice
  RATOR_ERROR_DENIED_CREDIT_CHECK_RESULT = 'RATOR_ERROR_DENIED_CREDIT_CHECK_RESULT',
  ERROR_HARDWARE_INVOICE_RESERVATION_NOT_APPROVED = 'ERROR_HARDWARE_INVOICE_RESERVATION_NOT_APPROVED',
  ERROR_HARDWARE_INVOICE_RESERVATION_FAILED = 'ERROR_HARDWARE_INVOICE_RESERVATION_FAILED',
  ERROR_HARDWARE_INVOICE_INSTALMENT_SETUP_FAILED = 'ERROR_HARDWARE_INVOICE_INSTALMENT_SETUP_FAILED',
  ERROR_HARDWARE_INVOICE_GET_CUSTOMER_FAILED = 'ERROR_HARDWARE_INVOICE_GET_CUSTOMER_FAILED',
  ERROR_HARDWARE_INVOICE_ACCEPT_TERMS_FAILED = 'ERROR_HARDWARE_INVOICE_ACCEPT_TERMS_FAILED',
  ERROR_HARDWARE_INSERT_INVOICE_FAILED = 'ERROR_HARDWARE_INSERT_INVOICE_FAILED',
  ERROR_HARDWARE_REVERT_INSERT_INVOICE_FAILED = 'ERROR_HARDWARE_REVERT_INSERT_INVOICE_FAILED',

  // Hardware stock levels
  ERROR_HARDWARE_OUT_OF_STOCK = 'ERROR_HARDWARE_OUT_OF_STOCK',
  ERROR_HARDWARE_STOCK_LEVEL_UNKNOWN_ERROR = 'ERROR_HARDWARE_STOCK_LEVEL_UNKNOWN_ERROR',

  // BankID
  ERROR_BANKID_SESSION_CANCELLED = 'ERROR_BANKID_SESSION_CANCELLED',
  ERROR_BANKID_FLOW_START_FAILED = 'ERROR_BANKID_FLOW_START_FAILED',
  ERROR_BANKID_APP_NOT_RESPONDING = 'ERROR_BANKID_APP_NOT_RESPONDING',
  ERROR_BANKID_APP_NOT_INSTALLED = 'ERROR_BANKID_APP_NOT_INSTALLED',
  ERROR_BANKID_ALREADY_IN_PROGRESS = 'ERROR_BANKID_ALREADY_IN_PROGRESS',
  ERROR_BANKID_INTERNAL_ERROR = 'ERROR_BANKID_INTERNAL_ERROR',
  ERROR_BANKID_INVALID_CERT = 'ERROR_BANKID_INVALID_CERT',
}
