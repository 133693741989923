import { Subscription } from './subscription';

export enum Brand {
  B2C = 'B2C',
  B2B = 'B2B',
}

export enum AccountUserRole {
  B2C = 'B2C',
  B2B = 'B2B',
  Guest = 'Guest',
  Customer = 'Customer',
  SalesAgent = 'SalesAgent',
  Support = 'Support',
}

export type Customer = {
  userId: string;
  personalId: string;
  customerNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  address?: string;
  street: string;
  streetNumber: string;
  city: string;
  zip: string;
  fullName: string;
};

export enum AccountStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Closed = 'Closed',
  Deactivated = 'Deactivated',
  Initial = 'Initial',
  Unknown = 'Unknown',
}

export type Address = {
  address: string;
  street: string;
  streetNumber: string;
  city: string;
  zip: string;
};

export type RawAccount = {
  data: {
    id: string;
    type: Brand;
    status: AccountStatus;
    canChange: boolean;
    customer: {
      userId: string;
      personalId: string;
      customerNumber: string;
      firstName: string;
      lastName: string;
      email: string;
      address: string;
      street: string;
      streetNumber: string;
      city: string;
      zip: string;
      fullName: string;
    };
    subscriptions: Subscription[];
  };
};

export enum LoginMethod {
  BANKID = 'bankid',
  EMAIL = 'email',
}

export type RatorAccount = {
  email: string;
  phoneNumbers: string[];
  userId: string;
  isActive: boolean;
};

export type UserObject = {
  accounts: RatorAccount[];
  hasMultipleAccounts: boolean;
  loginMethod?: LoginMethod;
  ownerId: string | null;
  email: string | null;
  roles: AccountUserRole[];
};

export type Account = RatorAccount & {
  canChange: boolean;
  customer: Customer;
  id: string;
  status: AccountStatus;
  subscriptions: Subscription[];
  type: Brand;
};

export type PaymentInfo = {
  lastDigits?: string;
  expireYear?: string;
  expireMonth?: string;
  cardType?: string;
  isInvoice: boolean;
};

export type ChangeEmailPayload = {
  email: string;
  password: string;
};

export type ChangePasswordPayload = {
  currentPassword: string;
  password: string;
};

export type ResetPasswordPayload = {
  email: string;
};

export type ChangeAliasPayload = {
  userId: string;
  subscriptionId: string;
  payload: {
    alias: string;
  };
};

export type ComplianceBase = {
  body: string;
  defaultValue: boolean;
  order: number;
  selected?: boolean;
  textId: string;
  title: string;
  version: string;
};

export enum ComplianceKey {
  Offers = 'offers',
  Traffic = 'traffic',
  Surveys = 'surveys',
}

export type ComplianceWithAdvertising = ComplianceBase & {
  advertisingPermission?: ComplianceBase[];
};

export type RemappedComplianceType = {
  offers: {
    body: string;
    questions: ComplianceWithAdvertising[];
  };
  traffic: {
    questions: ComplianceBase[];
  };
  surveys: {
    questions: ComplianceBase[];
  };
};

export type Compliance = {
  groups: ComplianceWithAdvertising[];
  standalones: ComplianceBase[];
};
